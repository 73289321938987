<template>
  <div class="main">
    <!-- 用户信息 -->
    <userData />
    <router-view />
  </div>
</template>

<script>
import userData from "@/components/userData";
export default {
  name: "",
  components: {
    userData,
  },
  data() {
    return {};
  },



};
</script>

<style scoped lang='less'>
.main {
  background: white;
}
</style>
