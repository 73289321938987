<template>
  <div class="main">
    <div v-if="infoData">
      <el-row class="user_info info_data">
        <span>学员姓名:{{ userInfo.user_name }}</span>
        <span>性别:{{ userInfo.is_sex == 1 ? " 男" : " 女" }}</span>
        <span>民族:汉</span>
        <span>分数:{{ userInfo.score }}</span>
        <span>科类:{{ userInfo.is_wenli == 1 ? " 文科" : " 理科" }}</span>
        <span>高中:{{ infoData.school }}</span>
        <span>英语:{{ infoData.english }}</span>
        <span>数学:{{ infoData.math }}</span>
        <span>语文:{{ infoData.chinese }}</span>
        <span>联系方式:{{ userInfo.mobile }}</span>
        <span>卡号:{{ userInfo.account }}</span>
      </el-row>
      <el-row class="user_info" v-if="infoData.like_sp">
        <span>意向专业:</span>
        <span class="item">{{ infoData.like_sp }}</span>
      </el-row>
      <el-row class="user_info" v-if="infoData.no_like_sp">
        <span>排除专业:</span>
        <span class="item">{{ infoData.no_like_sp }}</span>
      </el-row>
      <el-row class="user_info" v-if="infoData.like_city &&  infoData.like_city != '[]'">
        <span>喜欢城市:</span>
        <span class="item" v-for="(item,k) in JSON.parse(infoData.like_city)" :key="k">
          {{item}}
        </span>
      </el-row>
      <el-row class="user_info" v-if="infoData.no_like_city &&  infoData.no_like_city != '[]'">
        <span>排除城市:</span>
        <span class="item" v-for="(item,k) in JSON.parse(infoData.no_like_city)" :key="k">
          {{item}}
        </span>
      </el-row>
      <el-row class="user_info" v-if="infoData.special_request &&  infoData.special_request != '[]'">
        <span>特殊升学要求:</span>
        <span class="item" v-for="(item,k) in JSON.parse(infoData.special_request)" :key="k">
          {{item}}
        </span>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      userInfo: [],
      infoData: [],
    };
  },
  created() {
    this.user_id = this.$route.query.id;
    this.getUserInfo(this.user_id);
  },
  methods: {
    getUserInfo(id) {
      let data = {
        id: id,
      };
      this.$fecth.post("user/getUserInfo", data).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.userInfo = data;
          this.infoData = data.profile;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<!--user_info-->
<style lang="less" scoped>
.main {
  margin-top: 7px;
  background: white;
}
.user_info {
  line-height: 30px;
  padding: 5px;
  font-size: 14px;

  span {
    display: inline-block;
  }

  .item {
    background: #f2f2f2;
    padding: 0 7px;
    margin-right: 7px;
  }
}
.info_data {
  span {
    margin-right: 20px;
  }
}
.top {
  span {
    margin-right: 50px;
  }
}
.items {
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(221, 216, 216);
  .titles {
    line-height: 30px;
  }
}
</style>